export enum CommonLogTypes {
    WIFI_LINK_QUALITY = "WIFI_LINK_QUALITY",
    WIFI_SIGNAL = "WIFI_SIGNAL",
    CPU_TEMPERATURE = "CPU_TEMPERATURE",
    PING = "PING",
    NEW_DATA = "NEW_DATA",
}

export enum SmartCamDataLogType {
    CONFIG_LOADING = "CONFIG_LOADING",
    CAM_INFO = "CAM_INFO",
    API_UPLOAD = "API_UPLOAD",
    IMAGE_DOWNLOAD_INFO = "IMAGE_DOWNLOAD_INFO",
    IMAGE_UPLOAD_INFO = "IMAGE_UPLOAD_INFO",
}

export enum SmartCamMotorLogType {
    API_INFO = "API",
    GENERAL_INFO = "GENERAL_INFO",
    SETUP_INFO = "SETUP_INFO",
    HW_INFO = "HW_INFO",
    THREAD_INFO = "THREAD_INFO",
    CHRONOMETER_INFO = "CHRONOMETER_INFO",
    WAIT_EXECUTOR_INFO = "WAIT_EXECUTOR_INFO",
    LIMIT_SWITCH_INFO = "LIMIT_SWITCH_INFO",
    CAM_MEASUREMENT = "CAM_MEASUREMENT",
    PEN_POSITION = "PEN_POSITION",
    PEN_MOVING = "PEN_MOVING",
    MOTOR_MOVING = "MOTOR_MOVING",
    ENDSTOP_REACHED = "ENDSTOP_REACHED",
    STUCK_OUTSIDE_TRACK = "STUCK_OUTSIDE_TRACK",
    SAFE_EXIT = "SAFE_EXIT",
    RESET_TRIGGER = "RESET_TRIGGER",
    MOTOR_INFO = "MOTOR_INFO",
    DATA_MANAGER_INFO = "DATA_MANAGER_INFO",
    CAM_INFO = "CAM_INFO",    
    SMARTCAM_DATA_FETCH = "SMARTCAM_DATA_FETCH",
    INTERNAL_BUTTON_EVENT = "INTERNAL_BUTTON_EVENT",
    MOVEMENT_DURATION_ISSUE = "MOVEMENT_DURATION_ISSUE",
    AUTOCHECK_ISSUE = "AUTOCHECK_ISSUE",
}

export enum SmartFeedBalenaLogType {
    GENERAL_INFO = "GENERAL_INFO",
    OPERATION_MODE = "OPERATION_MODE",
    MAINTENANCE_MODE = "MAINTENANCE_MODE",
    DELAY_WAITING = "DELAY_WAITING",
    ERROR_INVALID_OPERATOR = "ERROR_INVALID_OPERATOR",
    SMARTFEED_INFO = "SMARTFEED_INFO",
    IMU_ERROR = "IMU_ERROR",
    IMU_MAX_TRIES = "IMU_MAX_TRIES",
    INVALID_LUNA_MEASUREMENT = "INVALID_LUNA_MEASUREMENT",
    LUNA_MAX_TRIES = "LUNA_MAX_TRIES",
    SWEEP_MEASURE_PROCESS = "SWEEP_MEASURE_PROCESS",
    SWEEP_MEASURE_PERSISTENCE = "SWEEP_MEASURE_PERSISTENCE",
    SWEEP_MEASURE_API_UPLOAD = "SWEEP_MEASURE_API_UPLOAD",
    SWEEP_MEASURE_SAFE = "SWEEP_MEASURE_SAFE",
    MULTIPLEXED_MEASURE_PROCESS = "MULTIPLEXED_MEASURE_PROCESS",
    IMU_CALIBRATION_PROCESS = "IMU_CALIBRATION_PROCESS",
    IMU_CALIBRATION_MAX_TRIES = "IMU_CALIBRATION_MAX_TRIES",
    IMU_CALIBRATION_PERSISTENCE_FAILED = "IMU_CALIBRATION_PERSISTENCE_FAILED",
    LASER_DATA_COMPARISON = "LASER_DATA_COMPARISON",
    LASER_DATA_CHANGE = "LASER_DATA_CHANGE",
    LASER_DATA_CHANGE_MEASURE = "LASER_DATA_CHANGE_MEASURE",
    IMU_DATA_CHANGE = "IMU_DATA_CHANGE",
    API_CONNECTION = "API_CONNECTION",
    PACKAGE_UPLOAD = "PACKAGE_UPLOAD",
    PACKAGE_PERSISTENCE = "PACKAGE_PERSISTENCE",
    PACKAGE_PERSISTENCE_CORRUPTED = "PACKAGE_PERSISTENCE_CORRUPTED",
}

export enum SmartMicBalenaLogType {
    MAINTENANCE_MODE = "MAINTENANCE_MODE",
    PRODUCTION_MODE = "PRODUCTION_MODE",
    DELAY_WAITING = "DELAY_WAITING",
    DEVICE_LISTING_INFO = "DEVICE_LISTING_INFO",
    DEVICE_SEARCHING_INFO = "DEVICE_SEARCHING_INFO",
    NETWORK_PARSE_ERROR = "NETWORK_PARSE_ERROR",
    GET_DEVICE_CONFIGURATION = "GET_DEVICE_CONFIGURATION", // Success/Failure
    DUMMY_SEARCHING = "DUMMY_SEARCHING", // In Progress [Level: Info] / Successful [Level: Success] / Failure [Level: Critical]
    DUMMYS_MISSING = "DUMMYS_MISSING", // Int number of Dummys missing
    DUPLICATE_DUMMY = "DUPLICATE_DUMMY", // Str dummys found info
    DUMMYS_FOUND = "DUMMYS_FOUND", // Int number of mics [Level: Success] if found all / [Level: Critical] if not found
    DUMMY_API_SEARCH_LOOPS_NEEDED = "DUMMY_API_SEARCH_LOOPS_NEEDED", // Int
    DUMMY_SSH_SEARCH_LOOPS_NEEDED = "DUMMY_SSH_SEARCH_LOOPS_NEEDED", // Int
    API_MICS = "API_MICS", // Int
    SSH_MICS = "SSH_MICS", // Int [Level: Warning] if > 0
    DUMMYS_FOUND_TABLE = "DUMMYS_FOUND_TABLE",
    DUMMY_NOT_FOUND = "DUMMY_NOT_FOUND", // Str dummy number
    DUMMY_WIFI_STATUS = "DUMMY_WIFI_STATUS", // Int signal strength, level may vary based on signal strength
    DUMMY_CPU_TEMPERATURE = "DUMMY_CPU_TEMPERATURE", // Int temperature, level may vary based on temperature
    DUMMY_RECORDING = "DUMMY_RECORDING", // In Progress [Level: Info] / Successful [Level: Success] / Failure [Level: Critical]
    DUMMY_RECORDING_INFO = "DUMMY_RECORDING_INFO",
    DUMMY_RECORDING_TIMEOUT = "DUMMY_RECORDING_TIMEOUT", //  Str dummy number
    DUMMY_RECORDING_ERROR = "DUMMY_RECORDING_ERROR", //  Str dummy number
    DUMMY_API_RECORDING_ERROR = "DUMMY_API_RECORDING_ERROR", //  Str dummy number
    AUDIO_COMPRESSING = "AUDIO_COMPRESSING",
    AUDIO_PERSISTENCE_INFO = "AUDIO_PERSISTENCE_INFO", // Str dummy number
    AUDIO_PERSISTENCE_SAVING_FAILED = "AUDIO_PERSISTENCE_SAVING_FAILED", // Str dummy number
    AUDIO_UPLOADING = "AUDIO_UPLOADING", // In Progress [Level: Info] / Successful [Level: Success] / Failure [Level: Error]
    AUDIO_UPLOADING_INFO = "AUDIO_UPLOADING_INFO", // In Progress [Level: Info] / Successful [Level: Success] / Failure [Level: Error]
    PERSISTENCE_AUDIO_UPLOADING = "PERSISTENCE_AUDIO_UPLOADING", // In Progress [Level: Info] / Successful [Level: Success] / Failure [Level: Error]
    SMARTMIC_PERSISTENCE_OVERLOAD = "SMARTMIC_PERSISTENCE_OVERLOAD", // Str number of audios [Level: Critical]
    SMARTMIC_CPU_TEMPERATURE = "SMARTMIC_CPU_TEMPERATURE", // Int temperature, level may vary based on temperature
    SMARTMIC_WIFI_STATUS = "SMARTMIC_WIFI_STATUS", // Int signal strength, level may vary based on signal strength
}
