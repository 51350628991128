import { defineStore } from "pinia";
import { useMainStore } from '@/store/main';
import { SensorLogsType } from "@/types/sensor_log";
import { LogType } from "@/types/log";
import { API_URL } from "@/utils/urls";

const MAIN_STORE = useMainStore()


export const useLogsStore = defineStore("logsStore", {
  state: () => ({
    sensors_logs: [] as SensorLogsType[],
  }),
  actions: {
    async setLogsData(pk: number, filter: string): Promise<void> {
      try {
        if (this.sensors_logs.find((log: SensorLogsType) => pk === log.sensor) !== undefined) return;
        const data = await MAIN_STORE.getFromAPI(`${API_URL}/sensors_logs/?ordering=-datetime_created&owner=${pk}&type=${filter}`)
        this.sensors_logs.push({"sensor": pk, "logs": data.results, "next": data.next})
      }
      catch(error) {throw Error(`${error}`)}
    },
    async setNewData(pk: number): Promise<void> {
      try {
        const sensors_logs = this.sensors_logs.find((item) => item.sensor === pk)
        if (sensors_logs == null) return;
        if (sensors_logs.next == null) return;
        const data = await MAIN_STORE.getFromAPI(sensors_logs.next)
        sensors_logs.logs = sensors_logs.logs.concat(data.results)
        sensors_logs.next = data.next
      }
      catch(error) {throw Error(`${error}`)}
    },
    async updateLogs(pk: number, filter: string): Promise<void> {
      try {
        const log_object = this.sensors_logs.find((log: SensorLogsType) => pk === log.sensor)
        if (log_object == null) return;

        const log_pk_list = log_object.logs.map((log: LogType) => log.pk)
        const data = await MAIN_STORE.getFromAPI(`${API_URL}/sensors_logs/?ordering=-datetime_created&owner=${pk}&type=${filter}`)

        data.results.forEach((log: LogType) => {
          if (log_pk_list.includes(log.pk)) return;
          log_object.logs.push(log)
        })
      }
      catch(error) {throw Error(`${error}`)}
    },
  },
  getters: {
    getLogsBySensors: (state) => {
      return (sensor_pk:  number): any[] | undefined => {
        const logs = state.sensors_logs.find((log: SensorLogsType) => sensor_pk === log.sensor)?.logs
        return logs?.sort((logA, logB) => new Date(logA.datetime_created).getTime() - new Date(logB.datetime_created).getTime())
      }
    },
    getNextBySensors: (state) => {
      return (sensor_pk:  number): string | undefined | null  => {
        const sensors_logs = state.sensors_logs.find((sensor_log: SensorLogsType) => sensor_pk === sensor_log.sensor)
        if (sensors_logs == null) return;
        return sensors_logs.next;
      }
    },
    getLastWifiLog(state) {
      return (sensor_pk:  number): string => {
        const wifi_logs = this.getLogsBySensors(sensor_pk)?.filter(log => log.type.includes("WIFI"))
        const last_log = wifi_logs != null ? wifi_logs[wifi_logs.length - 1] : undefined
        return last_log?.value
      }
    },
  },
});
